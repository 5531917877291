import countries from './countries.json';
import countriesDE from './countries_de.json';
import countryCodes from './country_codes.json';
import tenantsConfig from './tenants.config.json';
// import industryActivities from './industryActivities.json';
// import industryActivitiesDE from './industryActivities_de.json';
// import states from './states.json';
// import timezones from './timezones.json';
// import timezonesDE from './timezonesDE.json';
// import language from './language.json';
// import languageNew from './language_new.json';
// import mockAcademyElements from './mockAcademyElements.json';
// import SelectComponents from './component.json';
// import SelectComponentsEnglish from './component_en.json';

export {
  countries,
  countriesDE,
  countryCodes,
  tenantsConfig,
  // industryActivities,
  // states,
  // timezones,
  // mockAcademyElements,
  // SelectComponents,
  // SelectComponentsEnglish,
  // timezonesDE,
  // language,
  // languageNew,
  // industryActivitiesDE,
};
