import { countries, countriesDE, countryCodes, tenantsConfig } from './json';

import { pluck, uniq } from 'ramda';

export interface Tenant {
  tenant_name: string | null;
  legal_name: string;
  currency: string;
  currency_symbol: string;
  country_code: string;
  country_name: string;
  language_code: string;
  default_timezone: string;
  locale: string;
  is_international: boolean;
  is_default_language: boolean;
  is_default_country: boolean;
  params: string;
  redirect: string;
}

export type Language = string;
export type Country = string;
type CountryInfo = {
  short: string;
  long: string;
  de: string | null;
  en: string | null;
};

const countryInfo: CountryInfo[] = countryCodes.reduce((acc, info) => {
  const de = countriesDE.find((x) => x.key === info.long);
  const en = countries.find((x) => x.key === info.long);
  return de || en
    ? [
        ...acc,
        {
          short: info.short.toLowerCase(),
          long: info.long.toLowerCase(),
          de: de?.country.toLowerCase() || null,
          en: en?.country.toLowerCase() || null,
        },
      ]
    : acc;
}, [] as CountryInfo[]);

const internationalTenants: Tenant[] = [
  {
    tenant_name: null,
    legal_name: 'E. ZOLLER GmbH & Co. KG',
    currency: 'EUR',
    currency_symbol: '€',
    country_code: 'at',
    language_code: 'de',
    country_name: 'Österreich',
    default_timezone: 'Europe/Berlin',
    locale: 'de-AT',
    is_international: true,
    is_default_language: true,
    is_default_country: false,
    params: 'int/de',
    redirect: 'int/de/home',
  },
  {
    tenant_name: null,
    legal_name: 'E. ZOLLER GmbH & Co. KG',
    currency: 'EUR',
    currency_symbol: '€',
    country_code: 'ch',
    language_code: 'de',
    country_name: 'Schweiz',
    default_timezone: 'Europe/Berlin',
    locale: 'de-DE',
    is_international: true,
    is_default_language: true,
    is_default_country: false,
    params: 'int/de',
    redirect: 'int/de/home',
  },
  {
    tenant_name: null,
    legal_name: 'E. ZOLLER GmbH & Co. KG',
    currency: 'EUR',
    currency_symbol: '€',
    country_code: 'int',
    language_code: 'de',
    country_name: 'Deutschland',
    default_timezone: 'Europe/Berlin',
    locale: 'de-DE',
    is_international: true,
    is_default_language: false,
    is_default_country: false,
    params: 'int/de',
    redirect: 'int/de/home',
  },
  {
    tenant_name: null,
    legal_name: 'ZOLLER Inc.',
    currency: 'EUR',
    currency_symbol: '€',
    country_code: 'int',
    language_code: 'en',
    country_name: 'DEU',
    default_timezone: 'Europe/Berlin',
    locale: 'en-US',
    is_international: true,
    is_default_language: true,
    is_default_country: true,
    params: 'int/en',
    redirect: 'int/en/home',
  },
];

export const tenants: Tenant[] = tenantsConfig.tenants.reduce(
  (tenantsData, tenant) => [
    ...tenant.countries.reduce(
      (countries, country) => [
        ...countries,
        ...country.languages.map(
          (language) =>
            ({
              tenant_name: tenant.name,
              legal_name: tenant.legal_name,
              currency: tenant.currency,
              currency_symbol: tenant.currency_symbol,
              country_code: language.country_code,
              country_code_3letter: country.code_3letter.toLocaleLowerCase(),
              country_name: country.name,
              language_code: language.languages_locale,
              default_timezone: country.default_timezone,
              locale: language.locale,
              is_international: !!country.is_international,
              is_default_language: !!language.is_default,
              is_default_country: !!country.is_default,
              use_case: country.use_case,
              params: `${language.country_code}/${language.languages_locale}`,
              redirect: `${language.country_code}/${language.languages_locale}/home`,
            } as Tenant)
        ),
      ],
      [] as Tenant[]
    ),
    ...tenantsData,
  ],
  internationalTenants
);

export const appCountries = uniq(pluck('country_code', tenants));
export const appCountryLanguages = appCountries.reduce(
  (acc, country) => ({
    ...acc,
    [country]: uniq(
      pluck(
        'language_code',
        tenants.filter((x) => x.country_code === country)
      )
    ),
  }),
  {} as { [country: string]: string[] }
);

const getCountryCode = (tenant: string | null, country: string | null) => {
  if (!country) {
    return null;
  }
  const lowerInput = country.toLowerCase();

  const info = countryInfo.find(
    (info) =>
      info.short === lowerInput ||
      info.long === lowerInput ||
      info.de === lowerInput ||
      info.en === lowerInput
  );

  const config = tenants
    .filter((x) => tenant === null || x.tenant_name === tenant)
    .find((x) =>
      info
        ? x.country_code.toLowerCase() === info.short
        : x.country_name.toLowerCase() === lowerInput ||
          x.country_code.toLowerCase() === lowerInput
    );

  return config ? config.country_code.toLowerCase() : null;
};

const getLanguageCode = (country: string | null, language: string | null) => {
  if (country === null || language === null) {
    return null;
  }

  return tenants
    .filter((x) => x.country_code === country)
    .map((x) => x.language_code)
    .includes(language)
    ? language
    : null;
};

export const getTenantConfig = ({
  tenant = null,
  country = null,
  language = null,
}: {
  tenant?: string | null;
  country?: string | null;
  language?: string | null;
} = {}): Tenant => {
  const countryCode =
    getCountryCode(tenant, country) ||
    (typeof tenant === 'string' ? null : 'int');

  const languageCode = getLanguageCode(countryCode, language);

  return (
    tenants.find(
      (x) =>
        (tenant === null || x.tenant_name === tenant) &&
        ((countryCode === null && x.is_default_country) ||
          x.country_code === countryCode) &&
        ((languageCode === null && x.is_default_language) ||
          x.language_code === languageCode)
    ) || tenants[tenants.length - 1]
  );
};

// const test = {
//   country_de: getTenantConfig({ country: 'de' }),
//   tenant_de: getTenantConfig({ tenant: 'de' }),
//   tenant_us_country_de: getTenantConfig({ tenant: 'us', country: 'de' }),
//   country_de_wrong_lang: getTenantConfig({ country: 'de', language: 'asd' }),
//   tenant_de_country_us: getTenantConfig({ tenant: 'de', country: 'us' }),
//   tenant_us_country_us: getTenantConfig({ tenant: 'us', country: 'us' }),
//   country_us: getTenantConfig({ country: 'us' }),
//   tenant_de_country_at: getTenantConfig({ tenant: 'de', country: 'at' }),
//   tenant_us_country_at: getTenantConfig({ tenant: 'us', country: 'at' }),
//   country_at: getTenantConfig({ country: 'at' }),
//   country_pl: getTenantConfig({ country: 'pl' }),
//   tenant_us_country_pl: getTenantConfig({ tenant: 'us', country: 'pl' }),
//   tenant_de_country_pl: getTenantConfig({ tenant: 'de', country: 'pl' }),
// };

// console.log(test);

// debugger;
