export interface PaidEventAttendees {
  eventId: number;
  maxAttendeesReached: boolean;
  diff: number;
}

export interface ShowCartResult {
  content: CartContent[];
  checkPaidEventAttendees?: PaidEventAttendees[];
  cart_total: {
    total_netto: number;
    total_netto_before_voucher: number;
    total: number;
    due_now: number;
    due_now_netto: number;
    tax_total: number;
    tax_rates: {
      factor: string;
      factor_float: number;
      total: number;
    }[];
    shipping_total: {
      customer_data: { [key: string]: null | string };
      shipping_fee: number;
      netto_incl_shipping: number;
      brutto_incl_shipping: number;
      shipping_condition: {
        free_shipping: boolean;
        condition: string;
      };
    };
  };
  voucher: string | null;
  currency: string;
  tenant: string;
  country: string;
  hash: string;
  tax_exemption: boolean;
  tax_exemptionable: boolean;
  tax_exemption_name: string | null;
  tax_exemption_expiry: string | null;
  partial_shipment: boolean;
}

type CartContentItem<
  T extends {
    model: CartContentModel;
    details: { options: unknown };
  }
> = {
  id: number;
  amount: number;
  details: {
    id: number;
    name: string;
    availability: boolean;
    availability_date: string | null;
    deposit: number | null;
    deposit_netto: number | null;
    picture: string | null;
    price: number;
    price_netto: number;
    price_netto_before_voucher: number;
    tax: number;
    tax_factor: number;
    tax_info: string;
    tenant: string;
  } & T['details'];
} & T;

interface Attendee {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

export type PaidEventCartContent = CartContentItem<{
  model: 'event';
  details: {
    options: Attendee;
    utcStartDateTime: string;
    utcEndDateTimeOfLastSession: string;
  };
  attendee: Attendee;
}>;

export type ToolingProductCartContent = CartContentItem<{
  model: 'tooling_product';
  details: {
    options: unknown[];
    article_number: string;
    description: string;
  };
  options: unknown[];
}>;

export type CartContentModel = 'event' | 'tooling_product';
export type CartContent = PaidEventCartContent | ToolingProductCartContent;

([] as CartContent[]).map((content) => {
  if (content.model === 'event') {
    content.attendee.email;
  } else {
    content.details.description;
  }
});
