import { Route } from '@angular/router';
import { CountryGuard } from './guards';
import {
  ProductDetailsComponent,
  ProductSearchComponent,
} from './modules/components';

export const appRoutes: Route[] = [
  {
    path: ':country-code/:lang-code',
    canActivateChild: [CountryGuard],
    children: [
      {
        path: 'product-details/:key',
        component: ProductDetailsComponent,
        // data: {
        //   breadcrumb: 'menu.productDetails',
        // },
      },
      {
        path: 'ezed',
        component: ProductSearchComponent,
        // data: {
        //   breadcrumb: 'menu.productLib',
        // },
      },
    ],
  },
  { path: '', pathMatch: 'full', redirectTo: 'us/en/ezed' },
  { path: '**', redirectTo: 'us/en/ezed' },
];
