import { Environment } from '@lib/core';

export const environment: Environment = {
  production: true,
  apiBase: 'https://dev-api.myezset.com',
  oauth: {
    client_id: '3',
    client_secret: 'Wx6EmHkWJsYsQNWB6zFb9RhRoDNML2JA5bl8HFfA',
  },
  credentials: {
    user: 'development@freesixtyfive.de',
    pass: 'development',
  },
};
