<header>
  <div class="container">
    <div class="row">
      <div class="container header">
        <div class="header-toolbar">
          <div class="toolbar-before">
            <img
              (click)="redirectHome()"
              class="logo"
              src="..\assets\Logo_EZset_white.svg"
            />

            <h3>
              {{ 'generic.downloads' | translate }}
            </h3>
          </div>
          <div class="toolbar-after">
            <div>
              <div class="nav-button">
                <a
                  [attr.href]="'ezset.header.ezsetInfoLink' | translate"
                  target="_blank"
                >
                  {{ 'ezset.header.ezsetInfo' | translate }}
                </a>
              </div>
            </div>
            <div>
              <div class="nav-button">
                <a
                  [attr.href]="'ezset.header.contactLink' | translate"
                  target="_blank"
                >
                  {{ 'footer.contact' | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
