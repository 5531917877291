import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@lib/core';
import { DxToolbarModule } from 'devextreme-angular';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, DxToolbarModule, TranslateModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() showMenuToggle = true;
  @Output() menuToggle = new EventEmitter<void>();
  // constructor() {}
  toggleMenu = () => {
    this.menuToggle.emit();
  };
  constructor( private router: Router) {}

  redirectHome() {
      this.router.navigateByUrl('/ezed');
  }
}
