import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import {
  appCountries,
  appCountryLanguages,
  Country,
  getTenantConfig,
  Language,
  Tenant,
} from '../models';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { TranslateService } from '@ngx-translate/core';
import { loadMessages, locale } from 'devextreme/localization';
import { Observable, ReplaySubject } from 'rxjs';

import * as moment from 'moment';
import 'moment/min/locales';

// NOTE: import locales here
import '@angular/common/locales/global/bg';
import '@angular/common/locales/global/cs';
import '@angular/common/locales/global/da';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/en-GB';
import '@angular/common/locales/global/es'; // spanish
import '@angular/common/locales/global/fi';
import '@angular/common/locales/global/fr'; // french
import '@angular/common/locales/global/hr';
import '@angular/common/locales/global/hu';
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/ja';
import '@angular/common/locales/global/ko';
import '@angular/common/locales/global/nl';
import '@angular/common/locales/global/pl';
import '@angular/common/locales/global/pt'; // portuguese
import '@angular/common/locales/global/ro';
import '@angular/common/locales/global/ru';
import '@angular/common/locales/global/sk';
import '@angular/common/locales/global/sl';
import '@angular/common/locales/global/sv';
import '@angular/common/locales/global/tr';
import '@angular/common/locales/global/zh'; // simplified chinese

import csMessages from 'devextreme/localization/messages/cs.json';
import deMessages from 'devextreme/localization/messages/de.json';
import esMessages from 'devextreme/localization/messages/es.json';
import fiMessages from 'devextreme/localization/messages/fi.json';
import frMessages from 'devextreme/localization/messages/fr.json';
import huMessages from 'devextreme/localization/messages/hu.json';
import itMessages from 'devextreme/localization/messages/it.json';
import jaMessages from 'devextreme/localization/messages/ja.json';
import nlMessages from 'devextreme/localization/messages/nl.json';
import ptMessages from 'devextreme/localization/messages/pt.json';
import ruMessages from 'devextreme/localization/messages/ru.json';
import slMessages from 'devextreme/localization/messages/sl.json';
import svMessages from 'devextreme/localization/messages/sv.json';
import trMessages from 'devextreme/localization/messages/tr.json';
import zhMessages from 'devextreme/localization/messages/zh.json';

import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  shareReplay,
} from 'rxjs/operators';

export type LanguageInfo = {
  country: string;
  language: string;
  isInternational: boolean;
  locale: string;
};

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public readonly countries: Country[] = appCountries;
  public readonly countryLanguages = appCountryLanguages;

  get countrynav(): Country {
    return this.languageConfig?.country_code || 'int';
  }

  get languagenav(): Language {
    return this.languageConfig?.language_code || 'en';
  }

  get locale(): string {
    return (this.languageConfig?.locale || 'en-US').replace('_', '-');
  }

  private infoSubject = new ReplaySubject<LanguageInfo>(1);
  public languageConfig!: Tenant;

  public country$ = this.getInfo('country');
  public language$ = this.getInfo('language');
  public locale$ = this.getInfo('locale');
  public isInternational$ = this.getInfo('isInternational');
  public readonly routePrefix$ = this.infoSubject.pipe(
    map(({ language, country }) => `/${country}/${language}`)
  );

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    private translate: TranslateService,
    private router: Router,
    private location: Location
  ) {
    this.translate.setDefaultLang('en');
    loadMessages(csMessages);
    loadMessages(deMessages);
    loadMessages(esMessages);
    loadMessages(fiMessages);
    loadMessages(frMessages);
    loadMessages(huMessages);
    loadMessages(itMessages);
    loadMessages(jaMessages);
    loadMessages(nlMessages);
    loadMessages(ptMessages);
    loadMessages(ruMessages);
    loadMessages(slMessages);
    loadMessages(svMessages);
    loadMessages(trMessages);
    loadMessages(zhMessages);

    // registerLocaleData(localeDe, 'de-DE', localeDeExtra);

    const [, country, language] = this.location.path().split('/');
    this.useLang(country as Country, language as Language);

    this.router.events
      .pipe(
        filter((e): e is ActivationStart => e instanceof ActivationStart),
        map((e) => ({
          country: e.snapshot.paramMap.get('country-code') as Country,
          language: e.snapshot.paramMap.get('lang-code') as Language,
        }))
      )
      .subscribe(({ country, language }) => this.useLang(country, language));
  }

  private getInfo<K extends keyof LanguageInfo>(
    key: K
  ): Observable<LanguageInfo[K]> {
    return this.infoSubject.pipe(
      pluck(key),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  private useLang(country?: Country, language?: Language) {
    if (!country || !language) {
      return;
    }

    this.languageConfig = getTenantConfig({
      tenant: this.localStorage.getItem('tenant'),
      country,
      language,
    });

    console.log('languageService.useLang', {
      country,
      language,
      tenant: this.localStorage.getItem('tenant'),
      config: this.languageConfig,
    });

    this.translate.use(this.languagenav);
    moment.locale(this.languagenav);
    locale(this.locale);

    this.infoSubject.next({
      country: this.countrynav,
      language: this.languagenav,
      isInternational:
        this.languageConfig.is_international || this.countrynav === 'int',
      locale: this.locale,
    });
  }

  getLocale(lang: Language | Country = this.countrynav) {
    return this.locale;
  }

  getCountry() {
    return this.countrynav;
  }

  getTenant() {
    return this.languageConfig.tenant_name;
  }

  getLanguage() {
    return this.languagenav;
  }

  getRoutePrefix() {
    return `/${this.countrynav}/${this.languagenav}`;
  }

  getDateTimeFormats(
    target: 'api' | 'moment' | 'angular' | 'devextreme' = 'angular'
  ) {
    return target === 'angular'
      ? {
          month: 'MMMM',
          month_and_day: 'MMMM dd',
          month_and_year: 'MMMM yyyy',
          day: 'dd',
          day_name: 'EEEE',
          date: 'mediumDate',
          date_and_time: 'short',
          longDate: 'longDate',
          time: 'shortTime',
        }
      : target === 'moment'
      ? {
          month: 'MMMM',
          month_and_day: 'MMMM DD',
          month_and_year: 'MMMM YYYY',
          day: 'DD',
          day_name: 'dddd',
          date: 'L',
          date_and_time: 'lll',
          longDate: 'LL',
          time: 'LT',
        }
      : target === 'devextreme'
      ? {
          month: 'MMMM',
          month_and_day: 'monthAndDay',
          month_and_year: 'monthAndYear',
          day: 'dd',
          day_name: 'EEEE',
          date: 'shortDate',
          date_and_time: 'shortDateShortTime',
          longDate: 'shortDate',
          time: 'shortTime',
        }
      : {
          month: '',
          month_and_day: '',
          month_and_year: '',
          day: '',
          day_name: '',
          date: 'YYYY-MM-DD',
          date_and_time: 'YYYY-MM-DDTHH:mm:ss',
          longDate: '',
          time: 'HH:mm:ss',
        };
  }

  isInternational() {
    return this.languageConfig.is_international || this.countrynav === 'int';
  }

  /**
   * completes a link with the corrrect country/language pathing
   *
   * @param segments
   */
  mutateLinkParams(...segments: (string | number)[]) {
    return [
      '/',
      this.countrynav,
      this.languagenav,
      // INFO: enable segments like 'x/y' instead of only 'x','y'
      ...segments.reduce(
        (acc, segment) => [
          ...acc,
          ...(typeof segment === 'string' ? segment : segment.toString()).split(
            '/'
          ),
        ],
        [] as (number | string)[]
      ),
    ];
  }
}
