import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ApiComponentService, LanguageService } from '../../../services';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent implements OnInit {
  private key = 'value';
  public customerProduct: any;
  public imageBasePath = environment.apiBase;
  public language!: string;
  public country!: string;
  public tenant: any;

  public serialNumber = new FormControl();

  constructor(
    @Inject(LOCAL_STORAGE) readonly localStorage: Storage,
    private activatedRoute: ActivatedRoute,
    private apiComponentService: ApiComponentService,
    public languageService: LanguageService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.country = this.languageService.getCountry();
    this.tenant = this.localStorage.getItem('tenant');
    this.activatedRoute.params.subscribe((params) => {
      this.key = params['key'];
    });
  }

  getProductDocumentURL() {
    this.apiComponentService
      .read(
        'getCustomerProductDocumentUrl' +
          //this.accessCode.value
          '?query=' +
          this.serialNumber.value
      )
      .subscribe((data: any) => {
        if (data.success === true) {
          this.router.navigate([data.data]);
        } else {
          notify(
            this.translate.instant('notify.serialNumberNotFound'),
            'error',
            5000
          );
        }
      });
  }

  goBackToOverview() {
    this.router.navigate(
      this.languageService.mutateLinkParams('user', 'myproducts')
    );
  }
}
