import { NgModule } from '@angular/core';

import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { ProductSearchComponent } from './components/product-search/product-search.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxListModule } from 'devextreme-angular';

@NgModule({
  declarations: [ProductDetailsComponent, ProductSearchComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DxListModule,
  ],
  exports: [ProductDetailsComponent, ProductSearchComponent],
})
export class PagesModule {}
