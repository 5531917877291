import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import * as FileSaver from 'file-saver';
import { getType } from 'mime';
import { concatMap, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ApiComponentService, LanguageService } from '../../../services';

type DynamicDocs = {
  calibrationCerificates: any;
  declarationsOfConformity: any;
};

type APIProductDocument = {
  country: string;
  description: string;
  language: string;
  path: string;
  path_zoller: string;
  classification: string;
};

type APIProductPath = {
  path: {
    [category: string]: {
      [language: string]: [
        {
          file: string;
          name: string;
          hash: string;
          size: string;
          ext: string;
        }
      ];
    };
  };
  hash: string;
};

type ProductDocument = {
  description: string;
  hash: string;
  ext: string;
  name: string;
  size: string;
  file: string;
  classification: string;
};

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  private skipAccesCode = false;
  private key = 'value';
  public customerProduct: any;
  public imageBasePath = environment.apiBase;
  public documents: any = {};
  public documentsNew: any = {};
  public documentsElo: any = [];
  public language!: string;
  public country!: string;
  public tenant: any;
  // FIXME:
  public accessAllowedStep0 = true;
  public accessAllowedStep1 = false;
  public accessAllowed = false;
  // public accessAllowedStep0 = false;
  // public accessAllowedStep1 = false;
  // public accessAllowed = true;
  public email = new FormControl();
  public accessCode = new FormControl();
  public dynamicDocs: DynamicDocs = {
    declarationsOfConformity: {},
    calibrationCerificates: {},
  };

  constructor(
    @Inject(LOCAL_STORAGE) readonly localStorage: Storage,
    private activatedRoute: ActivatedRoute,
    private apiComponentService: ApiComponentService,
    public languageService: LanguageService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.downloadDynamicDocFile = this.downloadDynamicDocFile.bind(this);
  }

  ngOnInit(): void {
    this.setDialogStatus('');
    this.language = this.languageService.getLanguage();
    this.country = this.languageService.getCountry();
    this.tenant = this.localStorage.getItem('tenant');
    this.activatedRoute.params.subscribe((params) => {
      this.key = params['key'];
    });
    this.getProductDetails();
  }

  getProductDetails() {
    this.apiComponentService
      .read(
        'getCustomerProductbyUrlKey/' +
          this.key +
          '/' +
          //this.accessCode.value
          (this.accessCode.value || 'debug')
      )
      .pipe(
        concatMap((customerProductResult: any) =>
          this.apiComponentService
            .read(
              'dynamicDocsListAccesCode',
              'calibration?serialNumber=' +
                customerProductResult?.serial_number +
                '&accessCode=' +
                (this.accessCode.value || 'debug') +
                '&key=' +
                this.key
            )
            .pipe(
              map((calibrationDocs: any) => ({
                calibrationDocs,
                customerProductResult,
              }))
            )
        ),
        concatMap((combinedResult: any) =>
          this.apiComponentService
            .read(
              'dynamicDocsListAccesCode',
              'conformity_declaration?serialNumber=' +
                combinedResult.customerProductResult?.serial_number +
                '&accessCode=' +
                (this.accessCode.value || 'debug') +
                '&key=' +
                this.key
            )
            .pipe(
              map((conformityDocs: any) => ({
                conformityDocs,
                ...combinedResult,
              }))
            )
        )
      )
      .subscribe((result: any) => {
        this.customerProduct = result.customerProductResult;
        this.documentsElo = [];
        this.documentsElo.push(...result.conformityDocs.data.documents);
        this.documentsElo.push(...result.calibrationDocs.data.documents);
        this.documentsElo = this.documentsElo
          .map((x: any) => ({
            ...x,
            description: x.desc[this.language] || x.name,
          }))
          .sort((a: any, b: any) => a.description.localeCompare(b.description));

        this.documentsNew = result.customerProductResult.documents;

        /* "documents": [
            {
                "name": "KAL SMILE420X-04279 Zoller, ann arbor (77644)",
                "file": "KAL SMILE420X-04279 Zoller, ann arbor (77644).pdf",
                "ext": "pdf",
                "size": "59.58 KB",
                "serial": "SMILE420X-04279",
                "id": 3299634,
                "docType": "calibration",
                "desc": {
                    "de": "Calibration certificate KAL SMILE420X-04279 Zoller, ann arbor (77644)",
                    "en": "Calibration certificate KAL SMILE420X-04279 Zoller, ann arbor (77644)"
                }
            }
        ] */
        this.documents = (result.customerProductResult.documents || [])
          .map((x: APIProductDocument) => {
            const parsed: APIProductPath = JSON.parse(x.path);

            try {
              const [category] = Object.keys(parsed.path);
              const [lang] = Object.keys(parsed.path[category]);
              return {
                description: x.description,
                classification: x.classification,
                ...parsed.path[category][lang][0],
                hash: parsed.hash,
              } as ProductDocument;
            } catch (e) {
              return null;
            }
          })
          .filter((x: unknown) => x !== null)
          .reduce((acc: any, curr: any) => {
            if (acc[curr.classification]) {
              acc[curr.classification].push(curr);
            } else {
              acc[curr.classification] = [curr];
            }
            return acc;
          }, {});
      });

    // group result.customerProductResult.documents by field classification
    /* this.documents = result.customerProductResult.documents.reduce(
      (acc: any, curr: any) => {
        // doc.path = JSON.parse(doc.path);
        const path = JSON.parse(curr.path);
        if (acc[curr.classification]) {
          acc[curr.classification].push({
            ...curr,
            ...path,
          });
        } else {
          acc[curr.classification] = [
            {
              ...curr,
              ...path,
            },
          ];
        }
        return acc;
      },
      {}
    ); */
  }
  getAccesCode() {
    this.apiComponentService
      .read('getAccessCode/' + this.key + '/' + this.email.value)
      .subscribe(
        (result: any) => {
          if (result.success) {
            this.setDialogStatus('accesCodeWasSent');
          }
        },
        (error) => {
          const errors: string[] = [];
          Object.values(error.error.errors).forEach((t: any) => {
            let trans = '';
            const regex = /(?:#)(\S+?)(?:#)/gm;
            const parts = [...t.pop().matchAll(regex)];
            if (parts) {
              const fieldValues = {
                field: parts[1][1] ? this.translate.instant(parts[1][1]) : '',
                value: parts?.[2]
                  ? this.translate.instant(parts[2][1])
                  : this.translate.instant(parts[0][1]),
              };
              trans = this.translate.instant(parts[0][1], fieldValues);
            }
            errors.push(trans);
          });

          notify(errors.join(' \n'), 'error', 5000);
        }
      );
  }

  setDialogStatus(status: string): void {
    // set skipAccesCode=true on devmode:
    status = this.skipAccesCode ? 'statusCodeValid' : status;

    switch (status) {
      case 'start':
        this.accessAllowedStep0 = true;
        this.accessAllowedStep1 = false;
        this.accessAllowed = false;
        break;
      case 'statusCodeValid':
        this.accessAllowedStep0 = false;
        this.accessAllowedStep1 = false;
        this.accessAllowed = true;
        break;
      case 'accesCodeWasSent':
        this.accessAllowedStep0 = false;
        this.accessAllowedStep1 = true;
        this.accessAllowed = false;
    }
  }
  setAccessCode() {
    this.apiComponentService
      .read('setAccessCode/' + this.key + '/' + this.accessCode.value)
      .subscribe(
        (result: any) => {
          if (result.data?.codeStatus === 'valid') {
            this.getProductDetails();
            this.setDialogStatus('statusCodeValid');
          } else {
            // tbd
            this.setDialogStatus('start');
            notify(
              this.translate.instant(
                'documents.accessCodeHasExpiredGetNewCode'
              ),
              'warning',
              5000
            );
          }
        },
        (error) => {
          const errors: string[] = [];
          Object.values(error.error.errors).forEach((t: any) => {
            let trans = '';
            const regex = /(?:#)(\S+?)(?:#)/gm;
            const parts = [...t.pop().matchAll(regex)];

            if (parts) {
              const fieldValues = {
                field: parts[1][1] ? this.translate.instant(parts[1][1]) : '',
                value: parts?.[2]
                  ? this.translate.instant(parts[2][1])
                  : this.translate.instant(parts[0][1]),
              };
              trans = this.translate.instant(parts[0][1], fieldValues);
            }
            errors.push(trans);
          });

          notify(errors.join(' \n'), 'error', 10000);
        }
      );
  }
  goBackToOverview() {
    this.router.navigate(
      this.languageService.mutateLinkParams('user', 'myproducts')
    );
  }
  downloadFile(e: any, name: string, downloadObject: any) {
    e.preventDefault();
    const extension = name.substring(name.lastIndexOf('.') + 1);

    const jsonData: any = { name };

    // e.currentTarget.removeChild(document.getElementsByClassName('progressbar'));
    const progress = document.createElement('div');
    progress.classList.add('progressbar');
    // progress.style.width = '0px';

    e.currentTarget.appendChild(progress);
    let total =
      downloadObject?.size_bytes > 0 ? downloadObject.size_bytes : 10000;
    this.apiComponentService
      .downloadFileGet(
        'filedownload/' + this.key + '/' + this.accessCode.value,
        jsonData
      )
      .subscribe(
        (event: any) => {
          if (event.type === HttpEventType.DownloadProgress) {
            total = event.loaded > total ? event.loaded + 10000 : total;
            const progressvalue = Math.round((100 * event.loaded) / total);

            progress.innerHTML = progressvalue + '%';
            progress.style.backgroundSize = progressvalue + '%';
          }
          if (event.type === HttpEventType.Response) {
            progress.innerHTML = '100%';
            progress.style.backgroundSize = '100%';
            this.downloadFileHelper(
              event.body,
              String(
                downloadObject.name
                  ? downloadObject.name
                  : downloadObject.description.replace(/[^a-zA-Z0-9]/g, '-') +
                      '.' +
                      downloadObject.ext
              ),
              String(extension)
            );
          }
        },
        (error) => {
          notify(
            this.translate.instant('documents.accessDenied'),
            'error',
            10000
          );
        }
      );
  }

  downloadDynamicDocFile(
    e: any,
    name: string,
    downloadName: string,
    dynamicDoc: any,
    docType: any
  ) {
    e.preventDefault();
    const extension = name.substring(name.lastIndexOf('.') + 1);
    const jsonData: any = {
      serialNumber: dynamicDoc.serial,
      id: dynamicDoc.id,
      accessCode: this.accessCode.value || 'debug',
      docType,
      key: this.key,
    };

    this.apiComponentService
      .download('dynamicDocDownloadByKey', jsonData)
      .subscribe((data: any) => {
        this.downloadFileHelper(data, String(downloadName), String(extension));
      });
  }
  downloadFileHelper(data: any, name: string, extension: string) {
    const blob = new Blob([data], { type: String(getType(extension)) });
    FileSaver.saveAs(blob, name);
  }
}
