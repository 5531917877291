<div class="content-wrapper product-detail mt-50 mb-50">
  <div class="container container-big">
    <div class="row" *ngIf="accessAllowedStep0">
      <div class="col-12">
        <div class="info-box">
          <h1>
            {{ 'documents.accessNotAllowedHeadlineStep1' | translate }}
          </h1>
          <p>
            {{ 'documents.accessNotAllowedDescriptionStep1' | translate }}
          </p>
          <label for="email">{{ 'generic.email' | translate }}:</label><br />
          <input type="text" id="email" [formControl]="email" />
          <button class="button button-yellow mt-3" (click)="getAccesCode()">
            {{ 'documents.getAccessCodeNow' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="accessAllowedStep1">
      <div class="col-12">
        <div class="info-box">
          <h1>
            {{ 'documents.accessNotAllowedHeadlineStep2' | translate }}
          </h1>
          <p>
            {{ 'documents.accessNotAllowedDescriptionStep2' | translate }}
          </p>
          <label for="accessCode"
            >{{ 'documents.accessCode' | translate }}:</label
          ><br />
          <input type="text" id="email" [formControl]="accessCode" />
          <button class="button button-yellow mt-3" (click)="setAccessCode()">
            {{ 'documents.useAccessCode' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="accessAllowed">
      <div class="col-12">
        <div class="info-box">
          <div class="row">
            <div class="mobile col-12">
              <h1 class="p-headline">
                {{ customerProduct?.custom_name ?? customerProduct?.name }}
              </h1>
              <div class="row row-underline-grey mobile">
                <div class="col-12 serialnr">
                  <span>{{ 'generic.serialNumber' | translate }}</span
                  >&nbsp;
                  <span *ngIf="customerProduct?.serial_number">{{
                    customerProduct?.serial_number
                  }}</span>
                </div>
                <div class="col-12 col-production">
                  <span
                    class="serialnr"
                    *ngIf="customerProduct?.production_year"
                    >{{ customerProduct?.production_year }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="product-detail-image">
                <img
                  *ngIf="customerProduct"
                  class="product-image"
                  src="{{ imageBasePath + customerProduct?.picture }}"
                />
              </div>
              <div class="image-text">{{ 'offers.imageText' | translate }}</div>
            </div>

            <div class="col-12 col-lg-6">
              <h1 class="p-headline desktop">
                {{ customerProduct?.custom_name ?? customerProduct?.name }}
              </h1>
              <div class="row row-underline-grey desktop">
                <div class="col-12 serialnr">
                  <span>{{ 'generic.serialNumber' | translate }}</span
                  >&nbsp;
                  <span *ngIf="customerProduct?.serial_number">{{
                    customerProduct?.serial_number
                  }}</span>
                </div>
                <div class="col-12 col-production">
                  <span
                    class="serialnr"
                    *ngIf="customerProduct?.production_year"
                    >{{ customerProduct?.production_year }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div
                    class="row"
                    *ngFor="let documentsClassified of documents | keyvalue"
                  >
                    <div class="col-12">
                      <h2>
                        {{ $any(documentsClassified.key) | translate }}
                      </h2>
                      <dx-list
                        #list
                        class="document-list"
                        [dataSource]="$any(documentsClassified.value)"
                        [searchEnabled]="true"
                        searchExpr="description"
                        searchMode="contains"
                      >
                        <div *dxTemplate="let data of 'item'">
                          <div class="document-list-item">
                            <img
                              [src]="
                                '/assets/images/icons/' + data.ext + '-icon.svg'
                              "
                              class="ng-star-inserted"
                            />&nbsp;
                            <span
                              (click)="downloadFile($event, data.file, data)"
                              >{{ data.description }} (<span
                                class="uppercase"
                                >{{ data.ext }}</span
                              >
                              {{ data.size }})</span
                            >
                          </div>
                        </div>
                      </dx-list>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h2>
                        {{ 'documents.declarationOfConformity2' | translate }},
                        {{
                          'documents.calibrationbrationCertificate' | translate
                        }}
                      </h2>
                      <dx-list
                        #list
                        class="document-list"
                        [dataSource]="documentsElo"
                        [searchEnabled]="true"
                        searchExpr="description"
                        searchMode="contains"
                      >
                        <div *dxTemplate="let data of 'item'">
                          <div class="document-list-item">
                            <img
                              [src]="
                                '/assets/images/icons/' + data.ext + '-icon.svg'
                              "
                              class="ng-star-inserted"
                            />&nbsp;
                            <span
                              (click)="
                                downloadDynamicDocFile(
                                  $event,
                                  data.file,
                                  data.description,
                                  data,
                                  data.docType
                                )
                              "
                              >{{ data.description }} (<span
                                class="uppercase"
                                >{{ data.ext }}</span
                              >
                              {{ data.size }})</span
                            >
                          </div>
                        </div>
                      </dx-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
