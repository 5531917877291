<footer>
  <div class="container">
    <div class="row">
      <div class="container footer">
        <div class="footer-toolbar">
          <div>
            <div>
              <a
                [attr.href]="'ezset.footer.imprintLink' | translate"
                target="_blank"
              >
                {{ 'footer.imprint' | translate }}
              </a>
            </div>
            <div>
              <a
                [attr.href]="'ezset.footer.privacyPolicyLink' | translate"
                target="_blank"
              >
                {{ 'footer.privacyPolicy' | translate }}
              </a>
            </div>
            <div>
              <a
                [attr.href]="'ezset.footer.disclaimerLink' | translate"
                target="_blank"
              >
                {{ 'footer.disclaimer' | translate }}
              </a>
            </div>
            <div>
              <a
                [attr.href]="'ezset.footer.agbLink' | translate"
                target="_blank"
              >
                {{ 'footer.agb' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
