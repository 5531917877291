import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';

import { LanguageService } from '../services';

const redirects: { path: string; redirect: string }[] = [
  // { path: 'expert', redirect: 'expert/booking' }
];

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): UrlTree {
    const url = state.url.replace(/^\//, '');
    const { redirect } = redirects.find((x) => x.path === url) || {
      redirect: url,
    };

    // console.log({
    //   country: this.languageService.getCountry(),
    //   language: this.languageService.getLanguage(),
    // });

    return this.router.parseUrl(
      `/${this.languageService.getCountry()}/${this.languageService.getLanguage()}/${redirect}`
    );
  }
}
