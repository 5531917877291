import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Order, AcademyElement } from '../models';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiComponentService } from './api-component.service';

// Generated by https://quicktype.io

export interface GetUserPersonResult {
  user: User;
  company: Company;
  contact: Contact;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  address: string | null;
  houseNumber: string | null;
  postalCode: string | null;
  city: string | null;
  country: string;
  timezone: string;
  company: string;
  industry: string;
  language_locale: string;
  customerContactId: string | null;
}

export interface Company {
  name: string;
  legal_name: string | null;
  founder: string | null;
  address1: string;
  address2: string | null;
  housenumber: string | null;
  zip_code: string;
  city: string;
  country: string;
  language: string;
  email: string;
  phone_number: string;
  fax_number: string | null;
  founding_date: string | null;
  founding_location: string | null;
  number_of_employees: string | null;
  description: string;
  tax_exemption_certificate: string | null;
  tax_exemption_certificate_valid_to: string | null;
  tax_exemption_status: boolean;
}

export interface Contact {
  id: number;
  first_name: string;
  last_name: string;
  department: string | null;
  description: string | null;
  picture: string;
  phone_number: string;
  fax_number: string | null;
  email: string;
}

export interface UserEvent {
  id: number;
  subject: string;
  z_type: string;
  language: string;
  isLimited: boolean;
  shortDescription: string;
  thumbnail: null | null;
  timezone: string;
  utcStartDateTime: string;
  utcEndDateTime: string | null;
  utcEndDateTimeOfLastSession: string | null;
  organizers: unknown[];
  location: string | null;
  subtype: string | null;
  subtypeLogo: string | null;
  eventCategory: {
    id: number;
    name: string;
  };
  eventTopics: unknown[];
  industries: unknown[];
}

export type Voucher = {
  id: number;
  code: string;
  valid_from: string | null;
  valid_to: string | null;
  value: string | null;
  original_value: string | null;
  type: string;
  reduction_percentage: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // Variables
  apiUrl = environment.apiBase;
  /**
   * Constructor
   * @param http The http client object
   */
  constructor(private http: HttpClient, private api: ApiComponentService) {}
  /**
   * Get the userData of the current logged in user
   */
  public getUser() {
    return this.http.get(this.apiUrl + 'api/user', { responseType: 'json' });
  }

  /**
   * Get the userData of the current logged in user
   */
  public getUserPerson() {
    return this.api.read<GetUserPersonResult>('users/getuserdata');
  }

  public getUserHome() {
    return forkJoin([
      this.getUserPerson().pipe(catchError(() => of({}))),
      this.api.read<{ data: Order[] }>('products/customerOrders').pipe(
        map((result) => (result && result.data) || []),
        catchError(() => of([]))
      ),
      this.api.read<UserEvent[]>('events').pipe(
        map((data) =>
          data
            .filter((x) => new Date(x.utcStartDateTime) > new Date())
            .sort(
              (a, b) =>
                new Date(a.utcStartDateTime).getTime() -
                new Date(b.utcStartDateTime).getTime()
            )
        ),
        catchError(() => of([]))
      ),
      this.api.read<{ data: AcademyElement[] }>('academies').pipe(
        map(({ data }) =>
          data
            .filter(
              (x) =>
                x.type_id !== 4 &&
                (typeof x.visible_from !== 'string' ||
                  Date.now() > new Date(x.visible_from).getTime())
            )
            .sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            )
        ),
        catchError(() => of([]))
      ),
      this.api.read<{ success: boolean; data: Voucher[] }>(`voucher/list`).pipe(
        map(({ success, data }) => (success ? data : [])),
        catchError(() => of([]))
      ),
      this.api
        .read<{
          success: boolean;
          data: { description: string; url: string }[];
        }>('widget/survey')
        .pipe(
          map(({ success, data }): { description: string; url: string }[] =>
            !success ? [] : data
          ),
          catchError(() => of([]))
        ),
      this.api
        .read<{ success: boolean; data: string | string[] }>(
          'widget/customHomescreenContent'
        )
        .pipe(
          map(({ success, data }): string[] =>
            !success ? [] : typeof data === 'string' ? [data] : data
          ),
          catchError(() => of([]))
        ),
    ]) as Observable<
      [
        GetUserPersonResult,
        Order[],
        UserEvent[],
        AcademyElement[],
        Voucher[],
        { description: string; url: string }[],
        string[]
      ]
    >;
  }
}
