import { getTenantConfig } from '../models';

export const redirects: string[] = [
  'onboarding',
  'insert-email',
  'reset-password',
  'confirm',
  'login',
  'session-expired',
  'user-home',
  'product-details',
  'ezed',
  'shop',
  'live',
  'academy',
  'user/myproducts',
  'user/product-detail',
];
export const ignoreRedirect: string[] = [
  'zollerlogin',
  'promo',
  'product-details',
];
export const ignoreInternationalRedirects: string[] = ['product-details'];

export const ignoreRedirectWithParams: string[] = ['zed'];

export const checkRoute = ({
  params,
  path,
  tenant,
  country,
  language,
}: {
  params: string;
  path: string;
  tenant: string | null;
  country: string | null;
  language: string | null;
}): string | boolean => {
  let guard = getTenantConfig({
    tenant,
    country,
    language,
  });

  if (
    guard.is_international &&
    guard.tenant_name === 'de' &&
    !guard.params.startsWith('int') &&
    !ignoreInternationalRedirects.some((segment) =>
      path.startsWith(`/${segment}`)
    ) &&
    ignoreRedirectWithParams.every((segment) => !path.startsWith(`/${segment}`))
  ) {
    guard = tenant ? getTenantConfig({ tenant }) : getTenantConfig();
  }

  if (
    guard.is_international &&
    guard.params === params &&
    ignoreRedirect.some((segment) => path.startsWith(`/${segment}`))
  ) {
    return true;
  }

  if (
    ignoreRedirect.some((segment) => path.startsWith(`/${segment}`)) ||
    (params !== 'int/en' &&
      guard.params !== 'int/de' &&
      ignoreRedirectWithParams.some((segment) =>
        path.startsWith(`/${segment}`)
      ))
  ) {
    return true;
  }

  if (guard.params !== params || !path.length) {
    return redirects.some((segment) => path.startsWith(`/${segment}`))
      ? `${guard.params}${path}`
      : guard.redirect;
  }

  return true;
};

// import { getTenantConfig } from '@app/models';

// export const redirects: string[] = [
//   'onboarding',
//   'insert-email',
//   'reset-password',
//   'confirm',
//   'login',
//   'session-expired',
//   'user-home',
//   'product-details',
//   'shop',
//   'live',
//   'academy',
//   'user/myproducts',
// ];
// export const ignoreRedirect: string[] = [
//   'zollerlogin',
//   'promo',
//   'product-details',
// ];
// export const ignoreInternationalRedirects: string[] = ['product-details'];

// export const checkRoute = ({
//   params,
//   path,
//   tenant,
//   country,
//   language,
// }: {
//   params: string;
//   path: string;
//   tenant: string | null;
//   country: string | null;
//   language: string | null;
// }): string | boolean => {
//   if (
//     !tenant &&
//     ignoreInternationalRedirects.some((segment) =>
//       path.startsWith(`/${segment}`)
//     )
//   ) {
//     const config = getTenantConfig({ country: params.split('/')[0] });
//     if (config.params === params) {
//       return true;
//     }
//   }
//   let guard = getTenantConfig({
//     tenant,
//     country,
//     language,
//   });

//   if (ignoreRedirect.some((segment) => path.startsWith(`/${segment}`))) {
//     return true;
//   }

//   if (guard.params !== params || !path.length) {
//     return redirects.some((segment) => path.startsWith(`/${segment}`))
//       ? `${guard.params}${path}`
//       : guard.redirect;
//   }

//   return true;
// };
