<div class="content-wrapper product-detail mt-50 mb-50">
  <div class="container product-search">
    <div class="row">
      <div class="col-xl-6 col-12 info-left">
        <h1>
            {{ 'ezset.productSearch.searchProductSerialNumber' | translate }}
          </h1>
          <p>
            {{ 'ezset.productSearch.howToSearchForProductSerialNumber' | translate }}
          </p>
          <div class="download-helper">
            <img class="info-svg" src="/assets/images/icons/info.svg" />
            <div class="tooltiptext">
              <img src="/assets/images/products/Serialnumber.jpg" />
            </div>
          </div>
          <label for="serialNumber" translate>generic.serialNumber</label><br />
          <input type="text" id="serialNumber" [formControl]="serialNumber" />
          <button
            class="button button-yellow mt-3"
            (click)="getProductDocumentURL()"
          >
            {{ 'ezset.productSearch.search' | translate }}
          </button>
          
      </div>
      <div class="col-xl-6 col-12 info-right">
        <div class="info-box">
          <h1 [innerHTML]="'ezset.productSearch.headline' | translate"></h1>
          <p [innerHTML]="'ezset.productSearch.description' | translate"></p>
        </div>
      </div>
    </div>
  </div>
</div>
