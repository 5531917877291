import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, LOCATION } from '@ng-web-apis/common';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LanguageService } from './language.service';

@Injectable({ providedIn: 'root' })
export class ApiComponentService {
  // Variables
  // componentUrl = environment.apiBase + 'api/component/';
  componentUrl = environment.apiBase + '/api/ezset/';

  /**
   * Constructor
   * @param http The http client object
   */
  constructor(
    @Inject(LOCAL_STORAGE) readonly localStorage: Storage,
    @Inject(LOCATION) readonly location: Location,
    private http: HttpClient,
    private languageService: LanguageService,
    private translate: TranslateService
  ) {}

  private getOptions() {
    return {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ZollerSession: this.localStorage.getItem('session') || '',
        ZollerTenant: this.languageService.getTenant() || 'de',
        ZollerLanguage: this.languageService.getLanguage(),
        ZollerPath: this.location.pathname + this.location.search,
        ZollerCountry:
          this.languageService.getCountry() === 'int' ||
          !this.languageService.getTenant()
            ? 'de'
            : this.languageService.getCountry(),
        responseType: 'json',
      }),
    };
  }
  public read<T = any>(
    url: string,
    id: number | string | null = null,
    query = ''
  ): Observable<T> {
    let readUrl: string;
    if (id) {
      readUrl = this.componentUrl + url + '/' + id + query;
    } else {
      readUrl = this.componentUrl + url + query;
    }
    return this.http.get<T>(readUrl, this.getOptions()).pipe(
      tap((res) => {
        const notice: any = (res as any).notice;
        if (notice) {
          if (typeof notice === 'string') {
            notify(this.translate.instant(notice), 'warning', 3500);
          } else if (typeof notice === 'object' && notice?.message !== '') {
            notify(
              this.translate.instant(notice.message),
              notice?.type !== '' ? notice.type : 'warning',
              3500
            );
          }
        }
      })
    );
  }

  public select<T = any>(url: string, query = '') {
    return this.http.get<T>(
      `${this.componentUrl}${url}${query ? `?${query}` : ''}`,
      this.getOptions()
    );
  }

  public post<T = any, V = any>(url: string, data: V) {
    return this.http
      .post<T>(this.componentUrl + url, data, this.getOptions())
      .pipe(
        tap((res: any) => {
          if (res.notice) {
            if (typeof res.notice === 'string') {
              notify(this.translate.instant(res.notice), 'warning', 3500);
            } else if (
              typeof res.notice === 'object' &&
              res.notice?.message !== ''
            ) {
              notify(
                this.translate.instant(res.notice.message),
                res.notice?.type !== '' ? res.notice.type : 'warning',
                3500
              );
            }
          }
        })
      );
  }
  public postFormRequest<T = any, V = any>(url: string, data: V) {
    return this.http.post<T>(this.componentUrl + url, data, this.getOptions());
  }

  public put<T = any, V = any>(url: string, data: V) {
    return this.http.put<T>(this.componentUrl + url, data, this.getOptions());
  }

  public download(url: string, data: any = {}) {
    return this.http.post(this.componentUrl + url, data, {
      responseType: 'blob',
    });
  }

  public downloadFileGet(
    url: string,
    data: any = {}
  ): Observable<HttpEvent<any>> {
    return this.http.get(this.componentUrl + url, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/blob',
        'Content-Type': 'application/blob',
        ZollerSession: this.localStorage.getItem('session') || '',
        ZollerTenant: this.languageService.getCountry(),
        ZollerCountry: this.languageService.getCountry(),
        ZollerLanguage: this.languageService.getLanguage(),
      }),
      params: data,
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
    });
  }
  public downloadByGet(url: string) {
    return this.http.get(this.componentUrl + url, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/blob',
        'Content-Type': 'application/blob',
        ZollerSession: this.localStorage.getItem('session') || '',
        ZollerTenant: this.languageService.getCountry(),
        ZollerCountry: this.languageService.getCountry(),
        ZollerLanguage: this.languageService.getLanguage(),
      }),
      responseType: 'blob',
    });
  }
}
