import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { LOCAL_STORAGE, WINDOW } from '@ng-web-apis/common';
import { Observable, of } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { checkRoute } from './check-country';

@Injectable({
  providedIn: 'root',
})
export class CountryGuard implements CanActivate, CanActivateChild {
  private ipInfo$ = this.http
    .get<{ country?: string; ip?: string }>(
      'https://ipinfo.io/json?token=e40773b5d1316f'
    )
    .pipe(
      map(({ country = 'DE' }) => ({
        location: country,
      })),
      catchError(() => of({ location: 'DE' })),
      shareReplay(1)
    );

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(LOCAL_STORAGE) readonly localStorage: Storage
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.ipInfo$.pipe(
      map(({ location }) => {
        const params =
          next.params['country-code'] + '/' + next.params['lang-code'];
        const path = state.url.replace(`/${params}`, '');

        const result = checkRoute({
          params,
          path,
          tenant: null,
          country: location,
          language: null,
        });

        console.log('canActivate', { params, path, location, route: result });

        if (typeof result === 'string') {
          // IMPORTANT NOTE:
          // DO NOT CHANGE!!! AND DO NOT RELOAD PAGE ANYWHERE ELSE as it breaks language settings & translations
          this.router.navigateByUrl(result).then(() => {
            this.windowRef.location.reload();
          });
          return false;
        } else {
          return result;
        }
      })
    );
  }
}
